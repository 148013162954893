import { ICON, IMG, SITUATION_DIALOG } from "constant";
import { useDeviceType } from "hooks/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Dialog = () => {
  const { pathname } = useLocation();
  const [currentHeading, setCurrentHeading] = useState(pathname.split("/")[2]); // TODO;; 리펙토링 필요
  const [data, setData] = useState(SITUATION_DIALOG[currentHeading]);

  const [currentClick, setCurrentClick] = useState(0);

  useEffect(() => {
    setData(SITUATION_DIALOG[currentHeading]);
    setCurrentHeading(pathname.split("/")[2]);
  }, [currentHeading]);
  useEffect(() => {
    setData(SITUATION_DIALOG[currentHeading]);
    setCurrentHeading(pathname.split("/")[2]);
    onHoverBoxClick(0);
  }, [pathname]);

  const HOVER_DATA = [
    { title: "사건발생", src: IMG.HOVER_LEFT },
    { title: "경찰단계", src: IMG.HOVER_TOP },
    { title: "검찰단계", src: IMG.HOVER_RIGHT },
    { title: "법원단계", src: IMG.HOVER_BOTTOM },
  ];
  const [currentHover, setCurrentHover] = useState(0);
  const onHover = (idx) => {
    setCurrentHover(idx);
  };

  const onLeave = () => {
    setCurrentHover("");
    setCurrentHover(currentClick);
  };

  const onHoverBoxClick = (idx) => {
    setCurrentClick(idx);
    setCurrentHover(idx);
  };

  const deviceType = useDeviceType();

  return (
    <section className="section situation full">
      <div className="dialog">
        <div className="dialog-inner">
          <div className="head-title">
            <p className="title">
              법무법인 온강의 <strong>맞춤형 대응 SYSTEM</strong>
            </p>
            <span className="sub-title">
              <b>마약 사건을 직접 수사, 재판하던 전직 검사들</b>
              이 의뢰인 별로 <b>맞춤식 변론 전략</b>을 수립합니다,
            </span>
          </div>

          <div className="dialog-container">
            <div className="dialog-box">
              <img className="hover-img" src={require(`../../../../resources/image/situation/hover_${currentHover + 1}.png`)} alt="" />
              <ul className="dialog-list">
                {HOVER_DATA.map((item, idx) => {
                  return (
                    <li
                      className={`dialog-item ${currentClick === idx ? "active" : ""}`}
                      key={idx + Math.random()}
                      onMouseEnter={deviceType === "pc" ? () => onHover(idx) : null}
                      onMouseLeave={deviceType === "pc" ? () => onLeave() : null}
                      onClick={() => {
                        onHoverBoxClick(idx);
                      }}
                    >
                      <div className="dialog-item__inner">
                        <div className="dialog-text">
                          <span className="count">{"0" + (idx + 1)}</span>
                          <p className="title">{item.title}</p>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
              <div className="dialog-logo">
                <img src={ICON.ICON_WHITE_LOGO} alt="" />
              </div>
              <img className="triangle-img" src={IMG.TRIANGLE} alt="" />
            </div>
          </div>
          <div className="text-wrap">
            <p className="heading">{data.hoverData[currentClick].title}</p>

            <ul className="count-list">
              {data.hoverData[currentClick].countList?.map((countItem, countIdx) => {
                return (
                  <li className="count-item" key={countIdx + Math.random(1)}>
                    <div className="badge">{countIdx + 1}</div>
                    <p className="title">
                      {countItem.title}
                      <br />
                    </p>
                    {countItem.subTitle.map((countInnerItem, countInnerIdx) => {
                      return (
                        <p key={countInnerIdx + Math.random(2)} className={`sib-title ${countInnerItem.emphasize && "emphasize"}`}>
                          {countInnerItem.text}
                        </p>
                      );
                    })}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dialog;
