import MoreBtn from "components/layout/MoreBtn";
import { ICON, IMG, SITUATION_PROCESS } from "constant";
import { useDeviceType } from "hooks/useMediaQuery";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

const Process = () => {
  const { pathname } = useLocation();
  const [currentHeading, setCurrentHeading] = useState(pathname.split("/")[2]); // TODO;; 리펙토링 필요
  const [data, setData] = useState(SITUATION_PROCESS[currentHeading]);

  const deviceType = useDeviceType();

  const [isClick, setIsClick] = useState(false);
  const [currentClick, setCurrentClick] = useState(0);
  const processRef = useRef(null);

  const processData = [
    {
      title: "사건발생",
      src: IMG.PROCESS_01,
    },
    {
      title: "경찰단계",
      src: IMG.PROCESS_02,
    },
    {
      title: "검찰단계",
      src: IMG.PROCESS_03,
    },
    {
      title: "법원단계",
      src: IMG.PROCESS_04,
    },
  ];

  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    const header = document.querySelector("header"); // 원하는 header 선택자
    if (header) {
      setHeaderHeight(header.offsetHeight); // header의 높이를 상태로 저장
    }
  }, []);

  const onTogglePop = (idx) => {
    setIsClick((val) => !val);
    setCurrentClick(idx);
    console.log();
    if (processRef) {
      window.scrollTo({
        top: processRef.current.offsetTop - headerHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    setData(SITUATION_PROCESS[currentHeading]);
    setCurrentHeading(pathname.split("/")[2]);
  }, [currentHeading]);
  useEffect(() => {
    setData(SITUATION_PROCESS[currentHeading]);
    setCurrentHeading(pathname.split("/")[2]);
    setIsClick(false);
  }, [pathname]);
  return (
    <section className="section situation full">
      <div className="process">
        <div className="process-inner">
          <div className="head-title">
            <p className="title">
              <strong>법무법인 온강</strong>
              이 마약사건에 강한 이유
            </p>
            <span className="sub-title">법무법인 온강은 왜 다를까요?</span>
          </div>

          <ul className="process-list" ref={processRef}>
            {processData.map((item, idx) => {
              return (
                <li className="process-item" key={idx} onClick={deviceType === "pc" ? null : () => onTogglePop(idx)}>
                  <div
                    className="float-img-box"
                    style={{
                      backgroundImage: `url(${item.src})`,
                    }}
                  ></div>
                  <div className="text-wrap">
                    <div className="count">{"0" + (idx + 1)}</div>
                    <p className="title">{item.title}</p>
                    <MoreBtn onClickBtn={() => onTogglePop(idx)} title={"상세보기"} style={{ color: "#fff", borderColor: "white" }} icon={ICON.ICON_PLUS_WHITE} />
                  </div>
                </li>
              );
            })}

            {isClick && (
              <li className="process-item fix-pop">
                <div className="fix-pop-inner">
                  <div className="img-box">
                    <img src={processData[currentClick].src} alt="" />
                  </div>
                  <div className="text-wrap">
                    <div className="heading">
                      <span className="count">{currentClick + 1}</span>
                      <p className="title">{processData[currentClick].title}</p>
                    </div>
                    {data.popData[currentClick].item.important && <div className="emphasize-box">{data.popData[currentClick].item.important}</div>}
                    {/* &#8226;  */}
                    <ul className="desc-list">
                      {data.popData[currentClick].item.list?.map((popListItem, popListIdx) => {
                        return (
                          <li className="desc-item" key={popListIdx + Math.random()}>
                            <div className="title-box">{popListItem.title}</div>

                            <div className="sub-title-box">
                              {popListItem.subTitle.map((subTextItem, subTextIdx) => {
                                return (
                                  <>
                                    <p key={subTextIdx + Math.random()} className={`sub-title ${subTextItem.em && "strong"}`}>
                                      {subTextItem.text}
                                    </p>
                                    {subTextItem.innerList?.map((innerItem, innerIdx) => {
                                      return (
                                        <p key={innerIdx + Math.random()} className="in sub-title">
                                          {innerItem.text}
                                        </p>
                                      );
                                    })}
                                  </>
                                );
                              })}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <button className="close-btn" type="button" onClick={onTogglePop}>
                    <span>닫기</span>
                    <img src={ICON.ICON_CLOSE_WHITE} alt="" />
                  </button>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Process;
